import type { Token } from "@stripe/stripe-js";
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import {
  ONEOFF,
  REGULAR,
  STRIPE_PERCENTAGE,
  STRIPE_FIXED,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_CASH,
} from "~/lib/constants";
import { reactive, ref, watch } from "vue";

export const useBookingStore = defineStore("bookingStore", () => {
  const country = ref<string>("");

  const form = reactive({
    type: "Domestic",
    step: 1,
    service: null as string | null,
    frequency: null as string | null,
    oneoff_type: null as string | null,
    hours: null as number | null,
    days: null as number | null,
    is_dva: false,
    is_ndis: null as number | null,
    is_ndis_confirmed: false,
    preferred_days: [] as string[],
    start_date: null as Date | null,
    message: null as string | null,
    first_name: null as string | null,
    last_name: null as string | null,
    email: null as string | null,
    phone: null as string | null,
    suburb: undefined as any | undefined,
    address: null as string | null,
    address_suburb: null as string | null,
    address_state: null as string | null,
    address_postcode: null as string | null,
    payment_method: null as string | null,
    token: null as Token | null,
    submitting: false,
    api_error: null as any | null,
  });

  function nextStep() {
    if (form.step >= 6) {
      return;
    }

    form.step++;
  }

  function previousStep() {
    if (form.step <= 1) {
      return;
    }

    form.step--;
  }

  function setCountry(value: string) {
    country.value = value;
  }

  function setIsNDIS(value: number) {
    form.is_ndis = value;
  }

  function setStep(value: number) {
    form.step = value;
  }

  function setService(value: string) {
    form.service = value;
  }

  function isRegular() {
    return form.service === REGULAR;
  }

  function isOneoff() {
    return form.service === ONEOFF;
  }

  function getHourlyRate(service: string) {
    if (!form.suburb) {
      return null;
    }

    if (!service) {
      return;
    }

    return form.suburb.rates[String(service).toLowerCase()]["hourly_rate"];
  }

  function getAgencyFee(service: string) {
    if (!form.suburb) {
      return null;
    }

    return form.suburb.rates[String(service).toLowerCase()]["agency_fee"];
  }

  function getMinimumHours(service: string) {
    if (!form.suburb) {
      return null;
    }

    if (!service) {
      return;
    }

    return form.suburb.minimum_hours[String(service).toLowerCase()];
  }

  function getBaseRate(service: string) {
    if (!service) {
      return;
    }

    return (
      getMinimumHours(service) * getHourlyRate(service) +
      getAgencyFee(service)
    ).toFixed(2);
  }

  function usingCreditCard() {
    return form.payment_method === PAYMENT_METHOD_CREDIT_CARD;
  }

  function usingCash() {
    return form.payment_method === PAYMENT_METHOD_CASH;
  }

  function hasServiceFee() {
    return isRegular() && usingCreditCard();
  }

  function getTotal() {
    if (!form.suburb || !form.service || !form.type || !form.hours) {
      return (0.0).toFixed(2);
    }

    const rate = form.suburb.rates[form.service?.toLowerCase()];

    return (rate.hourly_rate * form.hours + rate.agency_fee).toFixed(2);
  }

  function getProcessingFee() {
    const fees = (getTotal() * (STRIPE_PERCENTAGE / 100) + STRIPE_FIXED) * 1.1;

    return (fees * 0.65).toFixed(2);
  }

  function isAU() {
    return country.value === "au";
  }

  function isNZ() {
    return country.value === "nz";
  }

  function isRegularEnabled() {
    return form.suburb?.regular_enabled;
  }

  function isCatchupEnabled() {
    return form.suburb?.catchup_enabled;
  }

  function isOneoffEnabled() {
    return form.suburb?.oneoff_enabled;
  }

  function getContactNumber() {
    if (isAU()) {
      return "1300 364 646";
    }

    return "0800 663 987";
  }

  function canUseCreditCard() {
    return (
      form.suburb?.credit_cards_enabled &&
      form.service !== ONEOFF &&
      form.type === "Domestic"
    );
  }

  function getSubmittableValues() {
    return {
      country: country.value,
      type: form.type,
      is_ndis: form.is_ndis,
      is_dva: form.is_dva,
      service: form.service,
      frequency: form.frequency,
      oneoff_type: form.oneoff_type,
      hours: form.hours,
      days: form.days,
      preferred_days: form.preferred_days,
      start_date: form.start_date,
      message: form.message,
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      phone: form.phone,
      suburb: form.suburb,
      address: form.address,
      state: form.address_state,
      postcode: form.address_postcode,
      payment_method: form.payment_method,
    };
  }

  async function submit(): Promise<any> {
    form.submitting = true;
    form.api_error = null;

    const uuid = useRoute().query.uuid;

    const formData = {
      ...getSubmittableValues(),
      uuid,
      suburb_id: form.suburb?.id,
      country: country.value?.toLowerCase(),
      days: form.days ?? 1,
      start_date: dayjs(form.start_date).format("D/MM/YYYY"),
      stripe_token: form.token?.id,
			is_ndis: parseInt(form.is_ndis),
    };

    return new Promise(async (resolve, reject) => {
      return await useApi()
        .post("/booking", formData)
        .then((response) => {
          Sentry.setTag("booking_created", response.data.data.unique_id);
          resolve(response);
        })
        .catch((error) => {
          form.api_error = error;

          if (error.response?.data) {
            Sentry.addBreadcrumb({
              category: "booking",
              message: "Error submitting booking",
              level: "debug",
              data: error.response?.data,
            });
          }

          Sentry.captureException(error);
          reject(error);
        })
        .finally(() => {
          form.submitting = false;
        });
    });
  }

  return {
    country,
    setCountry,
    setIsNDIS,
    setStep,
    setService,
    nextStep,
    previousStep,
    getTotal,
    isRegular,
    isOneoff,
    getHourlyRate,
    getAgencyFee,
    getMinimumHours,
    getBaseRate,
    getSubmittableValues,
    isAU,
    isNZ,
    isRegularEnabled,
    isOneoffEnabled,
    isCatchupEnabled,
    getContactNumber,
    canUseCreditCard,
    hasServiceFee,
    usingCreditCard,
    usingCash,
    getProcessingFee,
    submit,
    form,
  };
});
