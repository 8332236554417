import revive_payload_client_jkANPoXdP0 from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_qgjmavzjqseys33my7aoq6vbcu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xF13w8K9Df from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_qgjmavzjqseys33my7aoq6vbcu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VrmaaL1cKZ from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_qgjmavzjqseys33my7aoq6vbcu/node_modules/nuxt/dist/app/plugins/router.js";
import payload_client_pg1wy3mErg from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_qgjmavzjqseys33my7aoq6vbcu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_scmnWosF8q from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_qgjmavzjqseys33my7aoq6vbcu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aOvEX0BhLy from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/abdom-booking/abdom-booking/.nuxt/components.plugin.mjs";
import plugin_ao8yoReK5r from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@_xods4phaa7uzhl36j4diywu3si/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_DikPGOGvcb from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@_qgjmavzjqseys33my7aoq6vbcu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_o65dqgZrSe from "/home/runner/work/abdom-booking/abdom-booking/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@_3ydt4gj3nd3pumonzyrnppy6se/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import auto_animate_uTpJuTGsMC from "/home/runner/work/abdom-booking/abdom-booking/plugins/auto-animate.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/abdom-booking/abdom-booking/plugins/sentry.ts";
export default [
  revive_payload_client_jkANPoXdP0,
  unhead_xF13w8K9Df,
  router_VrmaaL1cKZ,
  payload_client_pg1wy3mErg,
  check_outdated_build_client_scmnWosF8q,
  plugin_vue3_aOvEX0BhLy,
  components_plugin_KR1HBZs4kY,
  plugin_ao8yoReK5r,
  chunk_reload_client_DikPGOGvcb,
  plugin_o65dqgZrSe,
  auto_animate_uTpJuTGsMC,
  sentry_3AyO8nEfhE
]